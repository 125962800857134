import _ from "lodash";
import moment from "moment";

import Base from "./Base";
import Category from "./Category";

export default class Product extends Base {
  constructor(props, children = []) {
    super();
    this.import(props, children);
  }

  import(props) {
    this.id = this.assign("id", props);
    this.sku = this.assign("sku", props);
    this.barcode = this.assign("barcode", props);
    this.title = this.assign("title", props);
    this.name = this.title;
    this.description = this.assign("description", props);
    // this.richDescription = this.assign('richDescription', props);
    this.metaTitle = this.assign("metaTitle", props);
    this.metaDescription = this.assign("metaDescription", props);
    this.price = this.assign("price", props);
    this.vat = this.assign("vat", props);
    this.oldPrice = this.assign("oldPrice", props);
    this.oldVat = this.assign("oldVat", props);
    this.discount = this.assign("discount", props);
    this.discountVat = this.assign("discountVat", props);
    this.percentageDiscount = this.assign("percentageDiscount", props);
    this.isCumulativeDiscount = this.assign(
      "isCumulativeDiscount",
      props,
      "bool"
    );
    this.sourcePrice = this.assign("sourcePrice", props, "float");
    this.vatBracket = this.assign("vatBracket", props, "float");
    this.totalPrice = this.assign("totalPrice", props);
    this.totalOldPrice = this.assign("totalOldPrice", props);
    this.totalDiscount = this.assign("totalDiscount", props);
    this.unit = this.assign("unit", props);
    this.unitId = this.assign("unitId", props);
    this.views = this.assign("views", props);
    this.score = this.assign("score", props);

    this.quantity = this.assign("quantity", props);
    this.service = this.assign("service", props, "bool");
    this.active = this.assign("active", props);
    this.activeOnline = this.assign("activeOnline", props);
    this.slug = this.assign("slug", props);
    this.availability = this.assign("availability", props);
    this.createdAt = props.createdAt ? moment(props.createdAt) : this.createdAt;
    this.updatedAt = props.updatedAt ? moment(props.updatedAt) : this.updatedAt;
    this.thumbnail = this.assign("thumbnail", props);
    this.images = this.assign("images", props);
    this.variant = this.assign("variant", props);
    this.stocks = this.assign("stocks", props);
    this.maxQuantity = this.assign("maxQuantity", props);

    this.preorderQuantity = this.assign("preorderQuantity", props);
    this.oneClickBuy = this.assign("oneClickBuy", props, "bool");

    this.prices = props.prices;

    this.availableFrom = props.availableFrom
      ? moment(props.availableFrom).format("YYYY-MM-DD")
      : this.availableFrom;

    this.preorderAvailable = this.assign("preorderAvailable", props);
    this.preorderStart = props.preorderStart
      ? moment(props.preorderStart).format("YYYY-MM-DD")
      : this.preorderStart;
    this.preorderEnd = props.preorderEnd
      ? moment(props.preorderEnd).format("YYYY-MM-DD")
      : this.preorderEnd;

    try {
      this.notifications = props.notifications.map((notification) => ({
        ...notification,
        extraData: JSON.parse(notification.extraData),
      }));
    } catch (e) {
      this.notifications = (props.notifications || []).map((notification) => ({
        ...notification,
        extraData: [],
      }));
    }

    if (
      !_.isEmpty(props.category) &&
      (!props.categoryId || props.categoryId === props.category.id)
    ) {
      this.category = new Category(props.category);
      this.categoryId = this.category.id;
    } else if (props.categoryId) {
      this.category = new Category({ id: props.categoryId });
      this.categoryId = props.categoryId;
    } else {
      this.category = new Category({});
      this.categoryId = 0;
    }

    this.attributes = {};

    this.recipe = {};

    if (props.recipe && !_.isEmpty(props.recipe)) {
      this.recipe = (props.recipe || []).map((recipeUnit) => {
        let rval = {
          sourceProductId: (
            (recipeUnit.ProductRecipe
              ? recipeUnit.ProductRecipe.sourceProductId
              : recipeUnit.sourceProductId) || 0
          ).toString(),
          label: recipeUnit.sku + " - " + recipeUnit.title || "Blank",
          sourceQuantity: recipeUnit.ProductRecipe
            ? recipeUnit.ProductRecipe.sourceQuantity
            : recipeUnit.sourceQuantity || 0,
        };

        return rval;
      });
    }

    if (props.attributes && !_.isEmpty(props.attributes)) {
      for (let i in props.attributes) {
        const attribute = props.attributes[i];
        this.attributes["attribute_" + attribute.id] = {
          id: attribute.id,
          type: attribute.type,
          name: attribute.name,
          description: attribute.description,
          value:
            attribute.value ||
            (attribute.ProductAttribute
              ? attribute.ProductAttribute.value
              : ""),
        };
      }
    }
  }

  export() {
    return {
      id: this.id,
      sku: this.sku,
      barcode: this.barcode,
      categoryId: this.categoryId,
      title: this.title,
      description: this.description,
      // richDescription: this.richDescription,
      maxQuantity: this.maxQuantity,
      metaTitle: this.title,
      metaDescription: this.metaDescription,
      sourcePrice: this.sourcePrice,
      active: !!this.active ? 1 : 0,
      service: !!this.service ? 1 : 0,
      activeOnline: !!this.activeOnline ? 1 : 0,
      slug: this.slug,
      vatBracket: this.vatBracket,
      images: this.images,
      availability: this.availability,
      attributes: this.attributes,
      variantId: this.variant.id,
      availableFrom: this.availableFrom,
      preorderAvailable: !!this.preorderAvailable ? 1 : 0,
      preorderStart: this.preorderStart,
      preorderEnd: this.preorderEnd,
      unitId: this.unitId || 1,
      recipe: this.recipe,
      preorderQuantity: this.preorderQuantity || 0,
      oneClickBuy: !!this.oneClickBuy ? 1 : 0,
    };
  }
}
