import { Button, Checkbox, Pane, TextInputField } from "evergreen-ui";
import { Formik } from "formik";
import * as moment from "moment";
import React, { Component } from "react";

export default class CardExpansionForm extends Component {
  render() {
    return (
      <Formik
        initialValues={this.props.initialValues}
        enableReinitialize={true}
        validationSchema={this.props.validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          this.props.onSave(values);
        }}
      >
        {({
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
        }) => {
          errors = Object.assign({}, this.props.errors, errors);

          return (
            <form>
              <TextInputField
                required
                label="Nume"
                name="name"
                description="Numele expansiunii"
                value={values.name || ""}
                onChange={handleChange}
              />
              <TextInputField
                required
                label="Code"
                name="code"
                description="Codul expansiunii"
                value={values.code || ""}
                onChange={handleChange}
              />
              <TextInputField
                required
                label="ID CardMarket"
                name="cardMarketId"
                description="Id-ul expansiunii pe cardmarket.com"
                value={values.cardMarketId || ""}
                onChange={handleChange}
              />
              <TextInputField
                required
                label="ID CardTrader"
                name="cardTraderId"
                description="Id-ul expansiunii pe cardtrader.com"
                value={values.cardTraderId || ""}
                onChange={handleChange}
              />

              <TextInputField
                required
                type="date"
                label="Data Lansare"
                name="releaseDate"
                description="Data lansarii expansiunii"
                value={values.releaseDate.format("YYYY-MM-DD")}
                onChange={(e) => {
                  setFieldValue(
                    "releaseDate",
                    moment(e.target.value, "YYYY-MM-DD")
                  );
                }}
                onBlur={handleBlur}
              />

              <br />

              <Checkbox
                name="active"
                label="Disponibil in general"
                checked={values.active || false}
                onChange={handleChange}
              />

              <Checkbox
                name="activeOnline"
                label="Disponibil pe site"
                checked={values.activeOnline || false}
                onChange={handleChange}
              />

              <Checkbox
                name="activeThirdParty"
                label="Disponibil pe platforme partenere (ex. CardMarket)"
                checked={values.availableThirdParty || false}
                onChange={handleChange}
              />

              <br />

              <TextInputField
                required
                label="Pret Common"
                name="customData.common"
                description="Pretul minim al unei carti common din set"
                value={values.customData?.common || 0}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextInputField
                required
                label="Pret Rare"
                name="customData.rare"
                description="Pretul minim al unei carti rare din set"
                value={values.customData?.rare || 0}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextInputField
                required
                label="Pret Super Rare"
                name="customData.superRare"
                description="Pretul minim al unei carti super rare din set"
                value={values.customData?.superRare || 0}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextInputField
                required
                label="Pret Ultra Rare"
                name="customData.ultraRare"
                description="Pretul minim al unei carti ultra rare din set"
                value={values.customData?.ultraRare || 0}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextInputField
                required
                label="Pret Secret Rare"
                name="customData.secretRare"
                description="Pretul minim al unei carti secret rare din set"
                value={values.customData?.secretRare || 0}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextInputField
                required
                label="Pret Ultimate Rare"
                name="customData.ultimateRare"
                description="Pretul minim al unei carti ultimate rare din set"
                value={values.customData?.ultimateRare || 0}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextInputField
                required
                label="Pret Ghost Rare"
                name="customData.ghostRare"
                description="Pretul minim al unei carti ghost rare din set"
                value={values.customData?.ghostRare || 0}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextInputField
                required
                label="Pret Token"
                name="customData.token"
                description="Pretul minim al unei carti token din set"
                value={values.customData?.token || 0}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextInputField
                required
                label="Pret Gold Rare"
                name="customData.goldRare"
                description="Pretul minim al unei carti gold rare din set"
                value={values.customData?.goldRare || 0}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextInputField
                required
                label="Pret Gold Secret Rare"
                name="customData.goldSecretRare"
                description="Pretul minim al unei carti gold secret rare din set"
                value={values.customData?.goldSecretRare || 0}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextInputField
                required
                label="Pret Mosaic Rare"
                name="customData.mosaicRare"
                description="Pretul minim al unei carti mosaic rare din set"
                value={values.customData?.mosaicRare || 0}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextInputField
                required
                label="Pret Oversized"
                name="customData.oversized"
                description="Pretul minim al unei carti oversized din set"
                value={values.customData?.oversized || 0}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextInputField
                required
                label="Pret Platinum Rare"
                name="customData.platinumRare"
                description="Pretul minim al unei carti platinum rare din set"
                value={values.customData?.platinumRare || 0}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextInputField
                required
                label="Pret Shatterfoil"
                name="customData.shatterfoil"
                description="Pretul minim al unei carti shatterfoil din set"
                value={values.customData?.shatterfoil || 0}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextInputField
                required
                label="Pret Starfoil Rare"
                name="customData.starfoilRare"
                description="Pretul minim al unei carti starfoil rare din set"
                value={values.customData?.starfoilRare || 0}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <TextInputField
                required
                label="Pret Special"
                name="customData.special"
                description="Pretul minim al unei carti special din set"
                value={values.customData?.special || 0}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <Pane borderTop="default" marginTop={16} paddingTop={16}>
                <Button type="submit" onClick={handleSubmit}>
                  Salveaza
                </Button>
              </Pane>
            </form>
          );
        }}
      </Formik>
    );
  }
}
