import ModuleConfig from "./Config";
import moment from "moment";
const actions = ModuleConfig.Actions;

const initialState = {
  data: [],
  count: 0,
  page: 1,
  pageCount: 1,
  pageSize: 20,
  search: "",
  searchColumn: "",
  sortDirection: "",
  current: {},
  errors: {},
  updated: moment().format("YYYYMMDDHHmmss"),
};

export default function products(state = initialState, action) {
  console.log(action);
  switch (action.type) {
    case actions.getAll.success.id:
      return Object.assign({}, state, {
        data: action.options.data,
        count: action.options.count,
        pageSize: action.options.pageSize,
        page: action.options.page,
        pageCount: action.options.pageCount,
        search: action.options.search,
        sortColumn: action.options.sortColumn,
        sortDirection: action.options.sortDirection,
        updated: moment().format("YYYYMMDDHHmmss"),
      });
    case actions.getOne.success.id:
    case actions.edit.success.id:
      return Object.assign({}, state, {
        current: action.options,
        errors: {},
      });
    case actions.edit.failure.id:
    case actions.create.failure.id:
      return Object.assign({}, state, {
        errors: action.options,
      });
    case actions.delete.success.id:
      return Object.assign({}, state, {
        current: {},
        errors: {},
      });
    case actions.activate.success.id:
      return Object.assign({}, state, {
        data: state.data.map((item) => {
          return {
            ...item,
            activeOnline:
              item.id === action.options.id ? true : item.activeOnline,
          };
        }),
      });
    case actions.deactivate.success.id:
      return Object.assign({}, state, {
        data: state.data.map((item) => {
          return {
            ...item,
            activeOnline:
              item.id === action.options.id ? false : item.activeOnline,
          };
        }),
      });
    default:
      return state;
  }
}
