import {
  Button,
  Checkbox,
  Heading,
  InlineAlert,
  Label,
  Pane,
  SelectField,
  Tab,
  TextInputField,
  Textarea,
} from "evergreen-ui";
import { Field, FieldArray, Formik } from "formik";
import _ from "lodash";
import React, { Component } from "react";
import { slugify } from "../../../util/Misc";

import KFileDrop from "../../common/FormComponents/KFileDrop";
import KMultipleSelectField from "../../common/FormComponents/KMultipleSelect";
import KRichTextArea from "../../common/FormComponents/KRichTextArea";
import ProductPicker from "./ProductPicker";
import ProductRecipe from "./ProductRecipe";

export default class ProductForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      selectedTab: "main",
    };
  }

  generateMetas = (values, setFieldValue) => {
    setFieldValue("metaTitle", values.title);
    setFieldValue("metaDescription", values.description);
    setFieldValue("slug", slugify(values.title));
  };

  onCategoryChange = (value, setFieldValue) => {
    setFieldValue("categoryId", value);
    this.props.onCategoryChange(value);
  };

  render() {
    return (
      <>
        <Formik
          initialValues={this.props.initialValues}
          enableReinitialize={true}
          validationSchema={this.props.validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values) => {
            if (
              values.sourcePrice === 0 &&
              !window.confirm("Pretul produsului este 0. Continui?")
            ) {
              return false;
            }

            this.props.onSave(values);
          }}
        >
          {({
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            setFieldValue,
          }) => {
            errors = Object.assign({}, this.props.errors, errors);

            return (
              <form>
                <Pane marginBottom={20}>
                  <Tab
                    isSelected={this.state.selectedTab === "main"}
                    onSelect={() => this.setState({ selectedTab: "main" })}
                  >
                    Date Principale
                  </Tab>
                  <Tab
                    isSelected={this.state.selectedTab === "images"}
                    onSelect={() => this.setState({ selectedTab: "images" })}
                  >
                    Imagini ({values.images ? values.images.length : "0"})
                  </Tab>
                  <Tab
                    isSelected={this.state.selectedTab === "attributes"}
                    onSelect={() =>
                      this.setState({ selectedTab: "attributes" })
                    }
                  >
                    Atribute &amp; Componente (
                    {this.props.category.attributes
                      ? this.props.category.attributes.length
                      : "0"}
                    )
                  </Tab>
                </Pane>
                <Pane>
                  {this.state.selectedTab === "main" ? (
                    <>
                      <Pane display="flex" width="100%">
                        <Pane
                          borderBottom="default"
                          paddingBottom={8}
                          marginBottom={8}
                          flexGrow={0.45}
                          marginRight={10}
                        >
                          <Heading size={500} marginBottom={16}>
                            DATE PRINCIPALE
                          </Heading>

                          <TextInputField
                            required
                            type="title"
                            label="Titlu"
                            name="title"
                            description="Numele produsului"
                            value={values.title || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.title}
                          />

                          <Label
                            htmlFor="description"
                            marginBottom={4}
                            display="block"
                          >
                            Descriere
                          </Label>

                          <KRichTextArea
                            onChange={(content, delta, ops, api) => {
                              setFieldValue("metaDescription", api.getText());
                              setFieldValue("description", api.getHTML());
                            }}
                            value={values.description}
                          />

                          {/* <Textarea
                            whiteSpace="pre-wrap"
                            spellcheck="false"
                            id="description"
                            name="description"
                            rows={15}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.description}
                            value={values.description}
                            placeholder="Descrierea vizibila in site a produsului"
                          /> */}
                          {errors.description ? (
                            <InlineAlert intent="danger">
                              {errors.description}
                            </InlineAlert>
                          ) : null}

                          <SelectField
                            label="Categorie"
                            name="categoryId"
                            description="Categoria din care va face parte produsul"
                            value={values.categoryId}
                            onChange={(e) => {
                              setFieldValue("attributes", {});
                              this.onCategoryChange(
                                e.target.value,
                                setFieldValue
                              );
                            }}
                            onBlur={handleBlur}
                          >
                            <option value="">Selecteaza o valoare</option>
                            {this.props.categories.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.label || category.title}
                              </option>
                            ))}
                          </SelectField>

                          <TextInputField
                            required
                            label="Cantitate Maxima Disponibila"
                            name="maxQuantity"
                            type="number"
                            description="Cantitatea disponibila care va fi afisata pe site"
                            value={values.maxQuantity || 0}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.maxQuantity}
                          />

                          <TextInputField
                            required
                            type="text"
                            label="Cod de bare"
                            name="barcode"
                            description="Codul de bare al produsului"
                            value={values.barcode || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.barcode}
                          />

                          <TextInputField
                            required
                            type="text"
                            label="SKU"
                            name="sku"
                            description="Codul produsului"
                            value={values.sku || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.sku}
                          />

                          <Checkbox
                            name="active"
                            label="Activ (produsul poate fi vandut)"
                            checked={values.active || false}
                            onChange={handleChange}
                          />

                          <Checkbox
                            name="activeOnline"
                            label="Activ Online (produsul apare pe site si pe alte platforme)"
                            checked={values.activeOnline || false}
                            onChange={handleChange}
                          />

                          <Checkbox
                            name="service"
                            label="Serviciu (produsul este un serviciu, nu un bun fizic)"
                            checked={values.service || false}
                            onChange={handleChange}
                          />

                          <Checkbox
                            name="oneClickBuy"
                            label="One Click Buy (Poate fi comandat cu un click - doar events momentan)"
                            checked={values.oneClickBuy || false}
                            onChange={handleChange}
                          />

                          <Textarea
                            marginTop={20}
                            id="comment"
                            label=""
                            name="comment"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.comment}
                            value={values.comment}
                            placeholder="Nota interna produs"
                          />

                          <Pane
                            display="flex"
                            alignItems="center"
                            borderBottom="default"
                            paddingBottom={8}
                            marginTop={20}
                            marginBottom={16}
                          >
                            <Heading size={500}>
                              DISPONIBILITATE SI PRECOMENZI
                            </Heading>
                          </Pane>
                          <TextInputField
                            required
                            type="date"
                            label="Data disponibilitate"
                            name="availableFrom"
                            description="Data de la care produsul se poate vinde"
                            value={values.availableFrom || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.availableFrom}
                          />

                          <Checkbox
                            name="preorderAvailable"
                            label="Produsul poate fi precomandat"
                            checked={values.preorderAvailable || false}
                            onChange={handleChange}
                          />

                          <TextInputField
                            required
                            type="text"
                            label="Cantitate disponibila per comanda"
                            name="preorderQuantity"
                            description="Nu are verificare de stoc. Se seteaza manual 0 cand vrem sa fie dezactivata precomanda"
                            value={values.preorderQuantity || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.preorderQuantity}
                          />
                        </Pane>
                        <Pane
                          borderBottom="default"
                          paddingBottom={8}
                          marginBottom={8}
                          flexGrow={0.45}
                        >
                          <Heading size={500} marginBottom={16}>
                            DATE PRETURI
                          </Heading>

                          <TextInputField
                            required
                            label="Pret (RON)"
                            name="sourcePrice"
                            type="number"
                            description="Pretul produsului (Fara TVA)"
                            value={values.sourcePrice}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.price}
                          />
                          <TextInputField
                            required
                            label="Cota TVA"
                            name="vatBracket"
                            description="Cota TVA a produsului"
                            value={values.vatBracket || 0}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.tax}
                          />

                          <SelectField
                            label="Unitate Masura"
                            name="unitId"
                            description="UM Produs"
                            value={values.unitId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            {this.props.units.map((unit) => (
                              <option key={`unit-${unit.id}`} value={unit.id}>
                                {unit.name}
                              </option>
                            ))}
                          </SelectField>

                          <SelectField
                            label="Disponibilitate"
                            name="availability"
                            description="Disponibilitatea produsului (daca cantitatea > 0)"
                            value={values.availability}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option key="available-option" value="available">
                              Disponibil
                            </option>
                            <option key="supplier-option" value="supplier">
                              In stoc furnizor
                            </option>
                            <option
                              key="coming-soon-option"
                              value="coming-soon"
                            >
                              In curand
                            </option>
                            <option
                              key="unavailable-option"
                              value="unavailable"
                            >
                              Indisponibil
                            </option>
                          </SelectField>

                          <Pane
                            display="flex"
                            alignItems="center"
                            borderBottom="default"
                            paddingBottom={8}
                            marginBottom={16}
                          >
                            <Heading size={500}>DATE SEO</Heading>
                            <Button
                              type="button"
                              appearance="minimal"
                              height={20}
                              marginLeft={16}
                              onClick={() =>
                                this.generateMetas(values, setFieldValue)
                              }
                            >
                              Genereaza automat
                            </Button>
                          </Pane>
                          <TextInputField
                            required
                            label="Meta Titlu"
                            name="metaTitle"
                            description="Titlul care apare pe Google"
                            value={values.metaTitle || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.metaTitle}
                          />

                          <Label
                            htmlFor="metaDescription"
                            marginBottom={4}
                            display="block"
                          >
                            Meta Descriere
                          </Label>
                          <Textarea
                            id="metaDescription"
                            name="metaDescription"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.metaDescription}
                            value={values.metaDescription}
                            placeholder="Descrierea vizibila pe google"
                          />
                          {errors.metaDescription ? (
                            <InlineAlert intent="danger">
                              {errors.metaDescription}
                            </InlineAlert>
                          ) : null}

                          <TextInputField
                            required
                            label="SLUG"
                            name="slug"
                            description="URL-ul la care va fi accesibil produsul"
                            value={values.slug || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.slug}
                          />
                        </Pane>
                      </Pane>
                    </>
                  ) : null}

                  {this.state.selectedTab === "images" ? (
                    <>
                      <Pane
                        borderBottom="default"
                        paddingBottom={8}
                        marginBottom={8}
                      >
                        <Heading size={500} marginBottom={16}>
                          IMAGINI
                        </Heading>

                        <Field component={KFileDrop} name="images" />
                      </Pane>
                    </>
                  ) : null}

                  {this.state.selectedTab === "attributes" ? (
                    <>
                      <Pane
                        borderBottom="default"
                        paddingBottom={8}
                        marginBottom={8}
                      >
                        <Heading size={500} marginBottom={16}>
                          ATRIBUTE &amp; COMPONENTE
                        </Heading>

                        <Pane
                          borderBottom="default"
                          paddingBottom={8}
                          marginBottom={8}
                        >
                          <Heading size={500}>Componente</Heading>
                        </Pane>

                        <FieldArray
                          name="recipe"
                          render={(arrayHelpers) => (
                            <Pane>
                              <Button
                                type="button"
                                margin={5}
                                onClick={() => arrayHelpers.push({})}
                              >
                                Componenta Noua
                              </Button>
                              {values.recipe && values.recipe.length > 0
                                ? values.recipe.map((product, index) => (
                                    <Pane>
                                      <ProductRecipe
                                        key={index}
                                        setFieldValue={setFieldValue}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        data={product}
                                        index={index}
                                        onRemove={(index) =>
                                          arrayHelpers.remove(index)
                                        }
                                      />
                                    </Pane>
                                  ))
                                : null}

                              <Button
                                type="button"
                                margin={5}
                                onClick={() => arrayHelpers.push()}
                              >
                                Componenta Noua
                              </Button>
                            </Pane>
                          )}
                        />

                        <Pane
                          borderBottom="default"
                          paddingBottom={8}
                          marginBottom={8}
                        >
                          <Heading size={500}>Varianta?</Heading>
                        </Pane>

                        <ProductPicker
                          minimal={true}
                          single={true}
                          value={values.variant}
                          onSubmit={(products) =>
                            setFieldValue(`variant`, products[0])
                          }
                        />

                        <Pane
                          borderBottom="default"
                          paddingBottom={8}
                          marginBottom={8}
                        >
                          <Heading size={500}>Alte Proprietati</Heading>
                        </Pane>
                        <FieldArray
                          name="attributes"
                          render={(arrayHelpers) =>
                            (!_.isEmpty(this.props.category)
                              ? this.props.category.attributes
                              : []
                            ).map((attribute, index) => {
                              switch (attribute.type) {
                                case "text-area":
                                  return (
                                    <React.Fragment key={attribute.name}>
                                      <Label
                                        htmlFor={attribute.name}
                                        marginBottom={4}
                                        display="block"
                                      >
                                        {attribute.name}
                                      </Label>
                                      <Textarea
                                        id={attribute.name}
                                        name={`attributes.attribute_${attribute.id}.value`}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `attributes.attribute_${attribute.id}`,
                                            {
                                              ...attribute,
                                              value: e.target.value,
                                            }
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        placeholder={attribute.description}
                                        value={
                                          values.attributes[
                                            `attribute_${attribute.id}`
                                          ]
                                            ? values.attributes[
                                                `attribute_${attribute.id}`
                                              ].value
                                            : ""
                                        }
                                      />
                                    </React.Fragment>
                                  );
                                case "multiple-select-enum":
                                  return (
                                    <KMultipleSelectField
                                      key={attribute.name}
                                      label={attribute.name}
                                      options={attribute.values}
                                      description={attribute.description}
                                      name={`attributes.attribute_${attribute.id}`}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `attributes.attribute_${attribute.id}`,
                                          {
                                            ...attribute,
                                            value: e.target.value,
                                          }
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      value={
                                        values.attributes[
                                          `attribute_${attribute.id}`
                                        ]
                                          ? values.attributes[
                                              `attribute_${attribute.id}`
                                            ].value
                                          : ""
                                      }
                                    />
                                  );
                                case "enum":
                                  return (
                                    <SelectField
                                      key={attribute.name}
                                      label={attribute.name}
                                      name={`attributes.attribute_${attribute.id}.value`}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `attributes.attribute_${attribute.id}`,
                                          {
                                            ...attribute,
                                            value: e.target.value,
                                          }
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      value={
                                        values.attributes[
                                          `attribute_${attribute.id}`
                                        ]
                                          ? values.attributes[
                                              `attribute_${attribute.id}`
                                            ].value
                                          : ""
                                      }
                                    >
                                      <option value="">
                                        Selecteaza o valoare
                                      </option>
                                      {attribute.values.map((value) => (
                                        <option
                                          key={value.value}
                                          value={value.value}
                                        >
                                          {value.value}
                                        </option>
                                      ))}
                                    </SelectField>
                                  );

                                case "text":
                                default:
                                  return (
                                    <TextInputField
                                      key={attribute.name}
                                      required
                                      label={attribute.name}
                                      name={`attributes.attribute_${attribute.id}`}
                                      description={attribute.description}
                                      value={
                                        values.attributes[
                                          `attribute_${attribute.id}`
                                        ]
                                          ? values.attributes[
                                              `attribute_${attribute.id}`
                                            ].value
                                          : ""
                                      }
                                      onChange={(e) => {
                                        setFieldValue(
                                          `attributes.attribute_${attribute.id}`,
                                          {
                                            ...attribute,
                                            value: e.target.value,
                                          }
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                  );
                              }
                            })
                          }
                        />
                      </Pane>
                    </>
                  ) : null}
                </Pane>

                <Button type="submit" onClick={handleSubmit}>
                  Salveaza
                </Button>
              </form>
            );
          }}
        </Formik>
      </>
    );
  }
}
