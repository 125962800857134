import React, { Component } from "react";
import { connect } from "react-redux";

import { getProduct, updateProduct } from "../actions/ProductActions";
import {
  getCategories,
  getCategory,
} from "../../categories/actions/CategoryActions";
import { getUnits } from "../../units/actions/UnitActions";

import { Pane, Heading, Button } from "evergreen-ui";
import InfoBlock from "../../common/InfoBlock/InfoBlock";

import { Link } from "react-router-dom";
import ProductForm from "../components/ProductForm";

import Configuration from "../Config";

const validation = Configuration.Validations.product;
const mapValidationErrors = Configuration.ErrorHandler.mapValidationErrors;

class EditCategory extends Component {
  constructor(props) {
    super(props);

    this.id = this.props.params.id;
  }
  async componentDidMount() {
    await this.props.dispatch(getProduct(this.id));
    await this.props.dispatch(getCategories());
    await this.props.dispatch(getUnits());

    this.props.dispatch(getCategory(this.props.product.categoryId));
  }

  handleSave = (values) => {
    this.props.dispatch(updateProduct(this.props.product.id, values));
  };

  render() {
    return (
      <Pane padding={16} paddingTop={0}>
        <Pane display="flex">
          <Pane>
            <Link to="/products">
              <Button iconBefore="arrow-left" intent="none">
                Inapoi la lista produse
              </Button>
            </Link>
          </Pane>

          <Pane marginLeft={16}>
            <Link to={`/products/${this.id}`}>
              <Button iconBefore="cross" intent="warning">
                Anuleaza modificarile
              </Button>
            </Link>
          </Pane>
        </Pane>

        <Pane
          flex={1}
          background="tint1"
          elevation={1}
          padding={16}
          marginTop={16}
        >
          <Heading size={600}>
            Modificare Produs - <strong>{this.props.product.title}</strong>
          </Heading>
        </Pane>

        <Pane
          display="flex"
          justifyContent="space-between"
          flex={1}
          marginTop={16}
        >
          <InfoBlock flex="1">
            <ProductForm
              isEdit={true}
              initialValues={this.props.product}
              validationSchema={validation}
              categories={this.props.categories}
              units={this.props.units}
              onSave={this.handleSave}
              errors={this.props.errors}
              onCategoryChange={(id) => {
                this.props.dispatch(getCategory(id));
              }}
              category={this.props.selectedCategory}
            />
          </InfoBlock>
        </Pane>
      </Pane>
    );
  }
}

function mapStateToProps(state) {
  return {
    errors:
      state.products.errors.type === "validation"
        ? mapValidationErrors(state.categories.errors.fields)
        : [],
    product: state.products.current,
    categories: state.categories.flatData,
    selectedCategory: state.categories.current,
    units: state.units.data,
  };
}

export default connect(mapStateToProps)(EditCategory);
